import service from './request'
//获取首页配置信息
export const getIndexPageConfig = params => {
  return service({
    url: '/index',
    method: 'GET',
    params
  })
}
//获取产品类型
export const getProductType = params => {
  return service({
    url: '/product/type',
    method: 'GET',
    params
  })
}
//获取产品品牌列表
export const getBrandList = params => {
  return service({
    url: '/brands',
    method: 'GET',
    params
  })
}
//获取手机机型
export const getPhoneModelList = params => {
  return service({
    url: '/models/list',
    method: 'GET',
    params
  })
}
//获取搜索列表
export const searchBrandList = params => {
  return service({
    url: '/search',
    method: 'GET',
    params
  })
}
//获取热搜关键字
export const getHotWord = params => {
  return service({
    url: '/hotsearch',
    method: 'GET',
    params
  })
}
//获取联系我们页面配置信息
export const getContactInfo = params => {
  return service({
    url: '/contactus',
    method: 'GET',
    params
  })
}
//获取型号详情
export const getModelDetail = params => {
  return service({
    url: '/models/details',
    method: 'GET',
    params
  })
}
//获取价格
export const getPrice = params => {
  return service({
    url: '/price',
    method: 'POST',
    params
  })
}

//获取环保机首页信息
export const getHsIndexData = params => {
  return service({
    url: '/protection/index',
    method: 'GET',
    params
  })
}

//获取环保机价格
export const getHsPrice = id => {
  return service({
    url: '/protection/show/'+id,
    method: 'GET',
  })
}

import axios from 'axios'
import qs from 'qs'

const baseURL = 'https://h5api.huishoubaojiadan.com/api'
let sign = window.location.hostname.split('.')[0].toString()
// let sign = '13802666666'

/****** 创建axios实例 ******/
const service = axios.create({
  baseURL: baseURL,  // api的base_url
  timeout: 5000,  // 请求超时时间
  headers: { 'sign': sign }
})

/****** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(config => {

  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  config.method == 'POST'
    ? config.data = qs.stringify({ ...config.data })
    : config.params = { ...config.params }

  return config
}, error => {  //请求错误处理
  alert(error)
  Promise.reject(error)
})

/****** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
  response => {  //成功请求到数据

    if (response.data.code === 200) {
      return response.data.data
    } else {
      alert(response.data.message);
    }
  },
  error => {  //响应错误处理
    return Promise.reject(error)
  }
)
export default service
